import { isAndroid, isIOS } from "react-device-detect";

(function () {
  if (window.location.hostname != 'link.listta.app') {
    return;
  }

  if (isIOS) {
    window.location = 'https://apps.apple.com/us/app/listta-daily-planner-agenda/id1556367668';
  } else if (isAndroid) {
    window.location = 'https://play.google.com/store/apps/details?id=app.listta.goals';
  }
})()